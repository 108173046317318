import { defineStore } from 'pinia'
import { useBranchStore } from '~/store/branches'
import type { Features } from '~/types/branch'

export const useTariff = defineStore('useTariff', () => {
  const store = useBranchStore()
  const { branch } = storeToRefs(store)
  const isVisibleForm = ref(false)
  const otherBranch = ref({
    id: undefined,
    name: undefined,
  })

  function show(branch_id?: number, branch_name?: string): void {
    otherBranch.value.id = branch_id
    otherBranch.value.name = branch_name
    isVisibleForm.value = true
  }

  function hide(): void {
    isVisibleForm.value = false
  }

  function checkFeature(id: keyof Features): boolean {
    if (branch.value.plan) {
      const features = branch.value.plan.features
      const keyIndex = Object.keys(features).findIndex(val => val === id)
      if (keyIndex > -1) {
        return features[id].is_active
      }
    }

    return false
  }

  return { isVisibleForm, show, hide, checkFeature, otherBranch }
})
